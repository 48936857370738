import React, { useCallback, useEffect, useState } from 'react';
import { ChevronDownIcon } from '@heroicons/react/solid';
import { Disclosure } from '@headlessui/react';
import { v4 as uuidv4 } from 'uuid';

import {
  managedFarm,
  rowCrops,
  specialtyCrops,
  windAndSolar,
  other,
} from '../assets/markers/_index';
import CountUp from './CountUp';
import FilterPanel from './FilterPanel';
import LeafletMap from './LeafletMap';
import LocationsTable from './LocationsTable';
import FarmTypeFilter from './FarmTypeFilter';

const ENDPOINT = window.location.hostname === 'localhost'
  ? 'https://farmlandpartners.com/graphql'
  : '/graphql';

const LOCATIONS_QUERY = `query NewQuery {
  locations(first:1000) {
    nodes {
      title
      slug
      link
      locationsMetadata {
        customFieldsLocationsAcres
        customFieldsLocationsCounty
        customFieldsLocationsFarmType
        customFieldsLocationsLatitude
        customFieldsLocationsLongitude
        customFieldsLocationsRegion
        customFieldsLocationsState
        customFieldsLocationsTillableAcres
        customFieldsLocationsTillableYearAcquired
        customFieldsLocationsDisclaimer
      }
    }
  }
}`;

const FILTERS = [
  {
    category: 'Region',
    options: [
      { label: 'West Coast' },
      { label: 'Central West' },
      { label: 'Central East' },
      { label: 'East Coast' },
    ],
  },
  {
    category: 'Property Type',
    options: [
      { label: 'Row Crops', marker: rowCrops, selected: 'frm-yellow' },
      { label: 'Specialty Crops', marker: specialtyCrops, selected: 'frm-blue' },
      { label: 'Managed Farm', marker: managedFarm, selected: 'frm-green' },
      { label: 'Wind and Solar', marker: windAndSolar, selected: 'frm-navy' },
      { label: 'Other', marker: other, selected: 'frm-red' },
    ],
  },
  {
    category: 'Plot Size',
    options: [
      { label: '< 100 acres' },
      { label: '101 - 500 acres' },
      { label: '501 - 1,000 acres' },
      { label: '> 1,001 acres' },
    ],
  },
  {
    category: 'Year Acquired',
    options: [
      { label: '2023' },
      { label: '2022' },
      { label: '2021' },
      { label: 'Older' },
    ],
  },
];

const App = () => {
  const isFarmView = window.location.pathname.includes('/frm-location');

  const [locations, setLocations] = useState();
  const [filteredLocations, setFilteredLocations] = useState();

  const sortByName = (arr) => {
    const sorted = [...arr];
    sorted.sort((a, b) => (a.title > b.title ? 1 : -1));
    return sorted;
  };

  useEffect(() => {
    const fetchLocations = async () => {
      try {
        const response = await fetch(ENDPOINT, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ query: LOCATIONS_QUERY }),
        });
        const responseJSON = await response.json();
        const { nodes: locationsArray } = responseJSON.data.locations;
        const locationsSorted = sortByName(locationsArray);
        setLocations(locationsSorted);
        setFilteredLocations(locationsSorted);
      } catch (err) {
        console.log('Error fetching locations: ', err);
      }
    };
    fetchLocations();
  }, [setLocations]);

  const showAllLabel = "Show All";
  const initialFilters = {
    'Region': showAllLabel,
    'Property Type': showAllLabel,
    'Plot Size': showAllLabel,
    'Year Acquired': showAllLabel,
  };
  const [filterInputs, setFilterInputs] = useState(initialFilters);

  const showFilterChips = () => {
    const show = Object.keys(filterInputs).some((filter) => {
      return filterInputs[filter] !== showAllLabel
    });
    return show;
  }

  const clearAllFilters = () => {
    setFilterInputs(initialFilters);
  }

  const resetFilter = (filter) => {
    let newFilters = {
      ...filterInputs
    };
    newFilters[filter] = showAllLabel;
    setFilterInputs(newFilters);
  }

  const filterByRegion = useCallback((region) => (
    filterInputs.Region === 'Show All'
    || region === filterInputs.Region
    || (region === 'West' && filterInputs.Region === 'West Coast')
    || (region === 'East' && filterInputs.Region === 'East Coast')
  ), [filterInputs.Region]);

  const filterByFarmType = useCallback((type) => (
    filterInputs['Property Type'] === 'Show All'
    || type === filterInputs['Property Type']
  ), [filterInputs]);

  const filterByPlotSize = useCallback((size) => {
    if (filterInputs['Plot Size'] === 'Show All') return true;
    return (
      (filterInputs['Plot Size'] === '< 100 acres'
        && parseInt(size, 10) <= 100)
      || (filterInputs['Plot Size'] === '101 - 500 acres'
        && parseInt(size, 10) > 100
        && parseInt(size, 10) <= 500)
      || (filterInputs['Plot Size'] === '501 - 1,000 acres'
        && parseInt(size, 10) > 500
        && parseInt(size, 10) <= 1000)
      || (filterInputs['Plot Size'] === '> 1,001 acres'
        && parseInt(size, 10) > 1000)
    );
  }, [filterInputs]);

  const filterByYearAcquired = useCallback((year) => {
    if (filterInputs['Year Acquired'] === 'Show All') return true;
    return (
      (year === filterInputs['Year Acquired'])
      || (filterInputs['Year Acquired'] === 'Older'
        && parseInt(year, 10) < 2021)
    );
  }, [filterInputs]);

  const handleFilterLocations = useCallback(() => {
    if (locations) {
      const filtered = locations.filter((location) => {
        const {
          customFieldsLocationsRegion,
          customFieldsLocationsFarmType,
          customFieldsLocationsAcres,
          customFieldsLocationsTillableYearAcquired,
        } = location.locationsMetadata;
        return (
          filterByRegion(customFieldsLocationsRegion)
          && filterByFarmType(customFieldsLocationsFarmType)
          && filterByPlotSize(customFieldsLocationsAcres)
          && filterByYearAcquired(customFieldsLocationsTillableYearAcquired)
        );
      });
      setFilteredLocations(filtered);
    }
  }, [filterByRegion, filterByFarmType, filterByPlotSize, filterByYearAcquired, locations]);

  useEffect(() => {
    handleFilterLocations();
  }, [handleFilterLocations, filterInputs]);

  const getTotalAcres = () => (
    filteredLocations
      .reduce(
        (acc, location) => (
          acc + Number(location.locationsMetadata
            .customFieldsLocationsAcres)),
        0,
      ));

  const getAcresManaged = () => (
    filteredLocations
      .filter(
        (location) => (
          location.locationsMetadata
            .customFieldsLocationsFarmType
          === 'Managed Farm'),
      )
      .reduce(
        (acc, location) => (
          acc + Number(location.locationsMetadata
            .customFieldsLocationsAcres)),
        0,
      ));

  const getAcresOwned = () => (getTotalAcres() - getAcresManaged());

  const portfolioDataPoints = [
    { label: 'Total Acres', getValue: getTotalAcres },
    { label: 'Acres Owned', getValue: getAcresOwned },
    { label: 'Acres Managed', getValue: getAcresManaged },
  ];

  return (
    locations && (
      <>
        {!isFarmView && (
          <>
            <div className="w-full blue-gradient text-center" style={{ paddingTop: '30px' }}>
              <div className="mx-auto mb-20px md:mb-0 pt-0 px-20px md:px-40px map-header-grid" style={{ maxWidth: '1200px' }}>
                {portfolioDataPoints.map((point) => (
                  <div key={uuidv4()} className="map-header-data-box">
                    <CountUp start={0} end={point.getValue() || 0} timer={10} />
                    <div className="mx-auto w-40px h-5px md:w-80px md:h-10px bg-frm-yellow" />
                    <div className="mx-auto w-70px md:w-fit mt-20px md:mt-30px map-header map-header-data-label lowercase md:capitalize">
                      {point.label}
                    </div>
                  </div>
                ))}
              </div>

              <Disclosure className="relative">
                {({ open, close }) => (
                  <>
                    <div className="flex map-justify-center flex-col-reverse sm:flex-row md:justify-end py-30px px-20px md:px-40px lg:px-90px items-center">
                      {showFilterChips() &&
                        <div className="flex flex-grow justify-start content-center flex-col-reverse sm:flex-row my-2">
                          <button className="mx-2 underline my-2" onClick={clearAllFilters}>Clear All</button>
                          <div className="flex flex-grow flex-wrap justify-start content-center">
                            {Object.keys(filterInputs).map((filter) => {
                              if (filterInputs[filter] !== "Show All") {
                                return <span className="inline-flex items-center py-0.5 pl-2 pr-0.5 rounded-full border text-xs font-medium text-frm-white mx-2 my-1" key={filter}>
                                  {filterInputs[filter]}
                                  <button
                                    type="button"
                                    className="flex-shrink-0 ml-0.5 h-4 w-4 rounded-full inline-flex items-center justify-center text-frm-white hover:text-frm-yellow focus:outline-none focus:bg-frm-yellow focus:text-white"
                                    onClick={() => resetFilter(filter)}
                                  >
                                    <span className="sr-only">remove {filterInputs[filter]} option</span>
                                    <svg className="h-2 w-2" stroke="currentColor" fill="none" viewBox="0 0 8 8">
                                      <path strokeLinecap="round" strokeWidth="1.5" d="M1 1l6 6m0-6L1 7" />
                                    </svg>
                                  </button>
                                </span>;
                              }
                              return;
                            })}
                          </div>
                        </div>
                      }
                      <Disclosure.Button className="flex items-start filters-heading justify-center">
                        <span className="md:mr-10px">
                          Filters
                        </span>
                        <ChevronDownIcon
                          className={`${open ? 'rotate-180 transform' : ''
                            } h-6 w-6 md:h-8 md:w-8 text-white`}
                          focusable={false}
                        />
                      </Disclosure.Button>
                    </div>
                    <Disclosure.Panel className="absolute z-2000 w-full blue-gradient -mt-0.5" style={{ padding: '0' }}>
                      <FilterPanel
                        close={close}
                        handleFilterLocations={handleFilterLocations}
                        filterInputs={filterInputs}
                        filterOptions={FILTERS}
                        setFilterInputs={setFilterInputs}
                      />
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>
            </div>
            <div className="md:hidden">
              <FarmTypeFilter
                farmTypes={FILTERS[1]}
                filterInputs={filterInputs}
                setFilterInputs={setFilterInputs}
              />
              <LocationsTable locations={filteredLocations} />
            </div>
          </>
        )}
        <div className={!isFarmView ? 'hidden md:block' : ''}>
          <LeafletMap isFarmView={isFarmView} locations={filteredLocations} />
        </div>
      </>
    )
  );
};

export default App;
