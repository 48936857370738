import React, { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

import {
  managedFarm,
  rowCrops,
  specialtyCrops,
  windAndSolar,
  other,
} from '../assets/markers/_index';
import {
  navArrowActive,
  navArrowInactive,
} from '../assets/pagination/_index';

const LocationsTable = ({ locations }) => {
  const itemsPerPage = 10;
  const [totalPages, setTotalPages] = useState(
    Math.round(locations.length / itemsPerPage),
  );
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    setTotalPages(Math.ceil((locations.length / itemsPerPage)));
    setCurrentPage(1);
  }, [locations]);

  const tableHeaders = [
    'Farm Name',
    'Total Acres',
    'Farm Acres',
  ];

  const farmTypeMarkers = {
    'Managed Farm': managedFarm,
    'Row Crops': rowCrops,
    'Specialty Crops': specialtyCrops,
    'Wind and Solar': windAndSolar,
    'Other': other,
  };

  const goToNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage((page) => page + 1);
    }
  };

  const goToPreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage((page) => page - 1);
    }
  };

  const getPaginatedLocations = () => {
    const startIndex = currentPage * itemsPerPage - itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    return locations.slice(startIndex, endIndex);
  };

  return (
    <div style={{ padding: '20px' }}>
      <h5 className="text-frm-navy" style={{ fontSize: '14px' }}>
        {`Showing: ${locations.length} ${locations.length === 1 ? 'Farm' : 'Farms'}`}
      </h5>

      <table className="w-full table-auto border-collapse">
        <thead>
          <tr>
            {tableHeaders.map((header, idx) => (
              <th key={header}>
                <h5
                  className={`text-frm-navy ${idx > 0 ? 'text-center mx-auto' : 'text-left'}`}
                  style={{ width: '60px', padding: '0', fontSize: '14px' }}
                >
                  {header}
                </h5>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {getPaginatedLocations().map((location) => {
            const {
              customFieldsLocationsState: state,
              customFieldsLocationsCounty: county,
              customFieldsLocationsAcres: totalAcres,
              customFieldsLocationsTillableAcres: tillableAcres,
              customFieldsLocationsFarmType: farmType,
            } = location.locationsMetadata;

            return (
              <tr key={uuidv4()} className="text-frm-body map-locations-table-border" style={{ fontSize: '14px' }}>
                <td className="py-20px flex items-center">
                  <img
                    src={farmTypeMarkers[farmType]}
                    alt={location.title.toLowerCase().includes('farm') ? location.title : `${location.title} Farm`}
                    className="mt-1 mr-10px"
                  />
                  <a
                    href={location.link}
                    className="!text-frm-body"
                  >
                    <div>{location.title}</div>
                    <div style={{ color: '#828282' }}>{`${county.toLowerCase().includes('county') ? county : `${county} County`}, ${state}`}</div>
                  </a>
                </td>
                <td className="py-20px text-center">
                  <div>{parseInt(totalAcres, 10).toLocaleString()}</div>
                  <div>{totalAcres === '1' ? 'Acre' : 'Acres'}</div>
                </td>
                <td className="py-20px text-center">
                  <div>{parseInt(tillableAcres, 10).toLocaleString()}</div>
                  <div>{tillableAcres === '1' ? 'Acre' : 'Acres'}</div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>

      <div style={{ paddingTop: '30px', paddingBottom: '60px' }}>
        <h5 className="mb-30px text-center text-frm-blue" style={{ padding: '0' }}>
          Pages
        </h5>

        <div className="mx-auto flex items-center map-justify-center">
          <button
            type="button"
            onClick={goToPreviousPage}
            className="mr-10px"
          >
            {currentPage === 1 ? (
              <img src={navArrowInactive} alt="Previous Page" />
            ) : (
              <img
                src={navArrowActive}
                alt="Previous Page"
                style={{ transform: 'scaleX(-1)' }}
              />
            )}
          </button>

          <span
            className="px-10px font-bold text-frm-navy"
            style={{ fontSize: '20px' }}
          >
            {currentPage}
          </span>
          <span
            className="px-10px text-frm-body font-normal"
            style={{ fontSize: '20px' }}
          >
            of
          </span>
          <span
            className="px-10px font-bold text-frm-navy"
            style={{ fontSize: '20px' }}
          >
            {totalPages || 1}
          </span>

          <button
            type="button"
            onClick={goToNextPage}
            className="ml-10px"
          >
            {currentPage === totalPages || !locations.length ? (
              <img
                src={navArrowInactive}
                alt="Next Page"
                style={{ transform: 'scaleX(-1)' }}
              />
            ) : (
              <img src={navArrowActive} alt="Next Page" />
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

export default LocationsTable;
