import React from 'react';

const FarmTypeFilter = (props) => {
  const { farmTypes, filterInputs, setFilterInputs } = props;

  return (
    <>
      <div className="hidden border-b-frm-yellow border-b-frm-blue border-b-frm-green border-b-frm-navy" />
      <div className="farm-type-filter-grid bg-frm-blue-neutral">
        {farmTypes.options.map((option) => (
          <button
            key={option.label}
            type="button"
            onClick={() => setFilterInputs((prevInputs) => {
              if (filterInputs['Property Type'] === option.label) {
                return { ...prevInputs, 'Property Type': 'Show All' };
              }
              return { ...prevInputs, 'Property Type': option.label };
            })}
            className={`w-full py-20px justify-self-center border-b-4 ${filterInputs['Property Type'] === option.label ? `border-b-${option.selected}` : ''}`}
          >
            <img
              src={option.marker}
              alt={`${option.label} Marker`}
              className="mx-auto"
            />
            <div
              className="mx-auto pt-10px text-frm-body text-center"
              style={{ fontSize: '12px', width: '58px' }}
            >
              {option.label}
            </div>
          </button>
        ))}
      </div>
    </>
  );
};

export default FarmTypeFilter;
