import React, {
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';

const CountUp = ({ start = 0, end, timer = 50 }) => {
  const [count, setCount] = useState(start);
  const ref = useRef(start);

  const accumulator = end / 200;

  const updateCount = useCallback(() => {
    if (ref.current < end) {
      const result = Math.ceil(ref.current + accumulator);
      if (result > end) return setCount(end);
      setCount(result);
      ref.current = result;
    }
    return setTimeout(updateCount, timer);
  }, [accumulator, end, timer]);

  useEffect(() => {
    updateCount();
  }, [updateCount, start, end]);

  return (
    <div className="map-header map-header-data mb-20px md:mb-30px">
      {count.toLocaleString()}
    </div>
  );
};

export default CountUp;
