import React, { useEffect, useState } from 'react';
import L from 'leaflet';
import {
  MapContainer,
  Marker,
  Popup,
} from 'react-leaflet';
import ReactLeafletKml from 'react-leaflet-kml';
import ReactLeafletGoogleLayer from 'react-leaflet-google-layer';
import { v4 as uuidv4 } from 'uuid';

import {
  managedFarm,
  markerShadow,
  rowCrops,
  specialtyCrops,
  windAndSolar,
  other
} from '../assets/markers/_index';
import stateAbbrToNamesMap from '../utils/stateAbbrToNames';

const LeafletMap = ({ isFarmView, locations }) => {
  const [kml, setKml] = useState(null);
  const kmlUrl = 'https://www.farmlandpartners.com/wp-content/uploads/2022/07/FarmlandPartnersInc.kml';

  useEffect(() => {
    fetch(kmlUrl)
      .then((res) => res.text())
      .then((kmlText) => {
        const parser = new DOMParser();
        const kmlParsed = parser.parseFromString(kmlText, 'text/xml');
        setKml(kmlParsed);
      });
  }, [setKml]);

  const farmTypeToIconMap = {
    'Managed Farm': managedFarm,
    'Row Crops': rowCrops,
    'Specialty Crops': specialtyCrops,
    'Wind and Solar': windAndSolar,
    'Other': other,
  };

  const getLocation = () => {
    const farm = window.location.pathname.split('/').at(-2);
    const locat = locations.find((loc) => loc.slug === farm);
    return locat;
  };

  const getMapCenter = () => {
    if (isFarmView) {
      const {
        customFieldsLocationsLatitude: lat,
        customFieldsLocationsLongitude: long,
      } = getLocation().locationsMetadata;
      return [lat * 1, long * 1];
    }
    return [38.8283, -95.5795];
  };

  const getIcon = (farmType) => (
    L.icon({
      iconUrl: farmTypeToIconMap[farmType],
      shadowUrl: markerShadow,
      iconSize: [36, 46],
      shadowSize: [36, 46],
      iconAnchor: [17, 38],
      shadowAnchor: [10, 45],
      popupAnchor: [0, -40],
    })
  );

  return (
    <div className="map">
      <MapContainer
        center={getMapCenter()}
        zoom={isFarmView ? 14 : 5}
        scrollWheelZoom={false}
      >
        <ReactLeafletGoogleLayer
          apiKey="AIzaSyBqc9qYcGIQAUYq1FAQN54a6UocsEJq4Ig"
          type="roadmap"
        />

        {locations.map((location) => {
          const {
            customFieldsLocationsLatitude: lat,
            customFieldsLocationsLongitude: long,
            customFieldsLocationsState: state,
            customFieldsLocationsCounty: county,
            customFieldsLocationsAcres: totalAcres,
            customFieldsLocationsTillableAcres: tillableAcres,
            customFieldsLocationsFarmType: farmType,
            customFieldsLocationsDisclaimer: disclaimer,
          } = location.locationsMetadata;

          const accessibilityLabel = location.title
            .toLowerCase()
            .includes('farm')
            ? location.title
            : `${location.title} Farm`;

          return (
            <Marker
              key={uuidv4()}
              position={[lat, long]}
              icon={getIcon(farmType)}
              alt={accessibilityLabel}
              title={accessibilityLabel}
              eventHandlers={{
                popupopen: async (e) => {
                  const buttons = await e.popup._container.getElementsByClassName('popup-focus');
                  if (buttons && buttons.length > 0) {
                    const focusbutton = buttons[0];
                    focusbutton.focus();
                  }
                },
                popupclose: (e) => {
                  e.sourceTarget._icon.focus();
                }
              }}
            >
              <Popup>
                <div className="filters-heading text-frm-navy w-full">
                  {location.title}
                </div>
                <div className="md:hidden font-bold text-frm-navy uppercase" style={{ marginTop: '10px' }}>
                  {`${county}${county.toLowerCase().includes('county') ? '' : ' County'}, ${stateAbbrToNamesMap[state]}`}
                </div>

                <div className="hidden md:block w-full">
                  <div
                    className="w-full mt-20px mb-20px py-20px border-b-2 border-t-2 border-white"
                    style={{ fontSize: '14px' }}
                  >
                    {/* The following block was commented out rather than removed per client request */}
                    {/*
                    <div className="mb-20px flex justify-between">
                      <span className="mr-10px">
                        {`LAT_${lat * 1}`}
                        &deg;
                      </span>
                      <span>
                        {`LONG_${long * 1}`}
                        &deg;
                      </span>
                    </div>
                    */}

                    <div className="font-bold text-frm-navy uppercase">
                      {`${county}${county.toLowerCase().includes('county') ? '' : ' County'}, ${stateAbbrToNamesMap[state]}`}
                    </div>
                    {(totalAcres && totalAcres.length !== 0) &&
                      <div className="mt-20px">
                        <span className="mr-20px font-bold text-frm-navy">
                          TOTAL ACRES:
                        </span>
                        <span>{parseInt(totalAcres, 10).toLocaleString()}</span>
                      </div>
                    }
                    {(tillableAcres && tillableAcres.length !== 0) &&
                      <div className="mt-20px">
                        <span className="mr-20px font-bold text-frm-navy">
                          TILLABLE ACRES:
                        </span>
                        <span>{parseInt(tillableAcres, 10).toLocaleString()}</span>
                      </div>
                    }
                  </div>
                  <div className="mb-10px font-bold text-frm-navy uppercase">
                    {farmType}
                  </div>
                  {disclaimer && (
                    <div className="mb-20px" style={{ fontSize: '14px', whiteSpace: 'normal' }}>
                      {disclaimer}
                    </div>
                  )}

                  <div style={{ paddingTop: '40px' }}>
                    <a
                      href={location.link}
                      className="w-fit mx-auto primary-button primary-button-navy popup-focus"
                    >
                      VIEW THIS PROPERTY
                    </a>
                  </div>
                </div>
              </Popup>
            </Marker>
          );
        })}
        {kml && <ReactLeafletKml kml={kml} />}
      </MapContainer>
    </div>
  );
};

export default LeafletMap;
