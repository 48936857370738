import React, { useEffect, useState } from 'react';

const FilterPanel = (props) => {
  const {
    close,
    filterInputs,
    filterOptions,
    setFilterInputs,
  } = props;

  const [inputs, setInputs] = useState(filterInputs);
  const [filterControls, setFilterControls] = useState([]);
  const [windowDimenion, detectHW] = useState({
    winWidth: window.innerWidth,
    winHeight: window.innerHeight,
  });

  const detectSize = () => {
    detectHW({
      winWidth: window.innerWidth,
      winHeight: window.innerHeight,
    });
  };

  useEffect(() => {
    window.addEventListener('resize', detectSize);
    return () => {
      window.removeEventListener('resize', detectSize);
    };
  }, [windowDimenion]);

  useEffect(() => {
    if (windowDimenion.winWidth < 768) {
      const farmTypeRemoved = [...filterOptions];
      farmTypeRemoved.splice(1, 1);
      setFilterControls(farmTypeRemoved);
    } else {
      setFilterControls(filterOptions);
    }
  }, [filterOptions, windowDimenion]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setInputs((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  return (
    <div className="w-full py-30px px-30px xl:px-120px">
      <div className="filter-panel-grid gap-16 whitespace-nowrap">
        {filterControls.map((item) => (
          <div key={item.category}>
            <div className="flex justify-between mb-20px mt-40px xl:mt-0 pb-10px border-b-2 border-white">
              <span className="filters-heading filters-category text-frm-yellow">
                {item.category}
              </span>
              <label
                htmlFor={item.category}
                className="filters-label"
              >
                <input
                  type="radio"
                  id={item.category}
                  name={item.category}
                  value="Show All"
                  checked={inputs[item.category] === 'Show All'}
                  onChange={handleInputChange}
                  className="mr-10px"
                />
                Show All
              </label>
            </div>

            <div className="filter-options-grid justify-start">
              {item.options.map((option) => (
                <div
                  key={option.label}
                  className="w-full h-map-marker md:pr-10px lg:pr-30px xl:pr-10px flex items-center justify-between"
                >
                  <label
                    htmlFor={option.label}
                    className="filters-label"
                  >
                    <input
                      type="radio"
                      id={option.label}
                      name={item.category}
                      value={option.label}
                      checked={inputs[item.category] === option.label}
                      onChange={handleInputChange}
                      className="mr-10px xl:mr-20px"
                    />
                    {option.label}
                  </label>
                  {option.marker && (
                    <img
                      src={option.marker}
                      alt={`${option.label} Marker`}
                    />
                  )}
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>

      <div className="mt-40px">
        <button
          type="button"
          className="w-fit mx-auto primary-button primary-button-blue"
          onClick={() => {
            close();
            setFilterInputs(inputs);
          }}
        >
          UPDATE
        </button>
      </div>
    </div>
  );
};

export default FilterPanel;
